import React from 'react'
import './footer.css'

const Footer = ({ backgroundColor }) => {
  return (
    <div className='footer' style={{ backgroundColor}}>
      <p>Copyright © 2022 - 2025 | Promec Pte Ltd. All rights reserved.</p>
    </div>
  )
}

export default Footer
